<template>
  <edit-template @confirm="handleSubmit" :confirmLoading="loading">
    <el-form ref="form" :model="formData" :rules="rules" label-width="160rem" v-loading="loading">
      <template v-if="id">
        <el-form-item label="学校名称">{{ school.name }}</el-form-item>
        <el-form-item label="学校所属城市">{{ school.city }}</el-form-item>
      </template>
      <template v-else>
        <el-form-item label="学校名称" prop="school_id">
          <el-select v-model="formData.school_id" placeholder="请选择">
            <el-option v-for="item in schoolOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校所属城市" prop="city">
          <el-cascader v-model="formData.city" :options="areaOptions" @change="handleCityChange"></el-cascader>
        </el-form-item>
      </template>
      <el-form-item label="当地计分方式">
        <div>{{ school.type_name }}</div>
      </el-form-item>
      <template v-if="id">
        <el-form-item label="入学年份">{{ formData.year }}</el-form-item>
      </template>
      <template v-else>
        <el-form-item label="入学年份" prop="year">
          <el-select v-model="formData.year">
            <el-option v-for="i in yearOptions" :key="i.value" :label="i.label" :value="i.value" />
          </el-select>
        </el-form-item>
      </template>
      <el-form-item label="第一档录取分" prop="first_line">
        <template v-if="formData.score_type === 1">
          <el-select v-model="formData.first_line" placeholder="请选择" @change="handleScoreChange($event, 0)">
            <el-option v-for="item in school.list" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <span class="ml-2">对应分数值 {{ formData.first_line_level }}</span>
        </template>
        <el-input v-else v-model="formData.first_line" :disabled="formData.score_type === ''" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="第二档录取分" prop="second_line">
        <template v-if="formData.score_type === 1">
          <el-select v-model="formData.second_line" placeholder="请选择" @change="handleScoreChange($event, 1)">
            <el-option v-for="item in school.list" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <span class="ml-2">对应分数值 {{ formData.second_line_level }}</span>
        </template>
        <el-input v-else v-model="formData.second_line" :disabled="formData.score_type === ''" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="语文合格得分率" prop="chinese_percent">
        <el-input v-model="formData.chinese_percent"  placeholder="请输入" style="width: 80rem !important;"></el-input>
        <span class="ml-1">%</span>
        <div class="mt-1" v-if="chinesePercent"><el-tag type="info"><i class="el-icon-info icon-style"></i>{{chinesePercent}}</el-tag></div>
      </el-form-item>
      <el-form-item label="数学合格得分率" prop="math_percent">
        <el-input v-model="formData.math_percent"  placeholder="请输入" style="width: 80rem !important;"></el-input>
        <span class="ml-1">%</span>
        <div class="mt-1" v-if="mathPercent"><el-tag type="info"><i class="el-icon-info icon-style"></i>{{mathPercent}}</el-tag></div>
      </el-form-item>
    </el-form>
  </edit-template>
</template>

<script>
import { changeOption, preciseDivide, preciseMultiply } from '@/utils'
import { getAreaDataMixin } from '@/utils/mixins'
import { zsPost, zsGet, get_2024schools, getAdjacentYears } from '@/api'
import { validateNumber } from '@/utils/validate'
export default {
	_config:{"route":[{"path":"edit","meta":{"title":"编辑"}}, {"path":"add","meta":{"title":"新增"}}]},
  mixins: [getAreaDataMixin],
  data() {

    return {
      id: '',
      yearOptions: [],
      loading: false,
      formData: { school_id: '', city: '', score_type: '', year: '', first_line: '', second_line: '', first_line_level: '', second_line_level: '', chinese_percent: '', math_percent: '' },
      rules: {
        school_id: [{ required: true, message: '请输入', trigger: 'change' }],
        city: [{ required: true, message: '请输入', trigger: 'change' }],
        year: [{ required: true, message: '请选择入学年份', trigger: 'change' }],
        first_line: [{ required: true, message: '请输入', trigger: 'change' }],
        second_line: [{ required: true, message: '请输入', trigger: 'change' }],
        chinese_percent: [{ required: true, validator: (_, value, callback) => validateNumber(value, callback, { min: 0, max: 100, equalToMin: true, equalToMax: true }), trigger: 'change' }],
        math_percent: [{ required: true, validator: (_, value, callback) => validateNumber(value, callback, { min: 0, max: 100, equalToMin: true, equalToMax: true }), trigger: 'change' }],
      },
      schoolOptions: [],
      cityOptions: [],
      school: { list: [], name: '', city: '', type_name: '' },
      score: { chinese: '', math: '' }
    }
  },
  computed: {
    chinesePercent() {
      if (!this.score.chinese || !this.formData.chinese_percent) return ''
      const val = Number(this.formData.chinese_percent)
      if (isNaN(val)) return ''
      const score = Number(this.score.chinese)
      const num = preciseDivide(val, 100, 4)
      return `${score} * ${num} = ${preciseMultiply(score, num, 4)}分`
    },
    mathPercent() {
      if (!this.score.math || !this.formData.math_percent) return ''
      const val = Number(this.formData.math_percent)
      if (isNaN(val)) return ''
      const score = Number(this.score.math)
      const num = preciseDivide(val, 100, 4)
      return `${score} * ${num} = ${preciseMultiply(score, num, 4)}分`
    }
  },
  created() {
    if(this.$route.query.id) {
      this.id = +this.$route.query.id
    } else {
      getAdjacentYears().then(res => {
        this.yearOptions = res
      })
    }
    this.getConfig()
    this.getData()
  },
  methods: {
    getConfig() {
      this.getAreaData()
      get_2024schools().then(res => {
        const { entity_school = [] } = res
        this.schoolOptions = changeOption({ list: entity_school, label: 'school_name', value: 'id' })
      })
    },
    getData() {
      if(!this.id) return
      zsGet('/recruit-2024/exam/get-exam-enroll-conf', { id: this.id }).then(res => {
        const { city_code, school_id, school_name, city_name, score_type, year, first_line_level, second_line_level, first_line, second_line, chinese_percent, math_percent } = res

        this.formData.school_id = school_id
        this.formData.city = ['', city_code]
        this.formData.score_type = score_type
        this.formData.year = year
        this.formData.first_line = first_line
        this.formData.second_line = second_line

        this.formData.chinese_percent = chinese_percent
        this.formData.math_percent = math_percent

        this.getCityScore(city_code, false)
        if(score_type) {
          this.formData.first_line = first_line_level
          this.formData.second_line = second_line_level
          this.formData.first_line_level = first_line
          this.formData.second_line_level = second_line
        }
        this.school.type_name = score_type ? '等级' : '分数'
        this.school.name = school_name
        this.school.city = city_name
      })
    },
    getCityScore(city_code, change = true) {
      zsGet('/recruit-2024/exam/get-city-score-type', { city_code }).then(res => {
        const { score_type, exam_rank, exam_subject: { math_subject_score: { score: math }, chinese_subject_score: { score: chinese } }} = res
        if(score_type) {
          this.school.list = changeOption({ list: exam_rank, label: 'level', value: 'level', all: true })
        }
        this.score = { chinese, math }
        if(!change) return
        this.school.type_name = score_type ? '等级' : '分数'
        this.formData.score_type = score_type
        this.formData.first_line = ''
        this.formData.second_line = ''
        this.formData.first_line_level = ''
        this.formData.second_line_level = ''
      }).catch(err => {
        this.score = { chinese: '', math: '' }
        if(!change) return
        this.school.type_name = ''
        this.formData.score_type = ''
        this.formData.first_line = ''
        this.formData.second_line = ''
        this.formData.first_line_level = ''
        this.formData.second_line_level = ''
        this.school.list = []
      }).finally(() => {
        this.loading = false
        this.$refs['form'].clearValidate(['first_line', 'second_line'])
      })
    },
    handleCityChange(val) {
      this.loading = true
      this.getCityScore(val[1])
    },
    handleScoreChange(val, type) {
      const obj = this.school.list.find(item => item.value === val)
      if(type) {
        this.formData.second_line_level = obj.score
      } else {
        this.formData.first_line_level = obj.score
      }
    },
    handleSubmit() {
      this.$refs['form'].validate(valid => {
        if(valid) {
          const { city, score_type, first_line_level, second_line_level, first_line, second_line, ...other } = this.formData
          const data = { ...other, score_type, first_line, second_line, city_code: city[1] }
          if(score_type) {
            data.first_line = first_line_level
            data.first_line_level = first_line
            data.second_line = second_line_level
            data.second_line_level = second_line
          }
          if(this.id) data.id = this.id
          const api = `/recruit-2024/exam/${this.id ? 'edit' : 'add'}-exam-enroll-conf`
          this.loading = true
          zsPost(api, data).then(() => {
            this.$message.success(`${this.id ? '编辑' : '新增'}成功`)
            this.$router.back()
          }).finally(() => this.loading = false)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-style {
  margin-right: 2rem;
  color: #bbb;
}
</style>
